<template>
  <validation-observer
    ref="refFormObserver"
    #default="{ invalid }"
  >
    <b-modal
      id="modal-add-documents-vn1a"
      :title="$t('reservation.addDocuments.title')"
      title-class="text-airline font-medium-4 font-weight-bolder"
      header-bg-variant="light-info"
      centered
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
      @show="showHandle"
    >
      <div class="mb-50">
        <div class="font-medium-4 fw-700">
          {{ $t('reservation.bookingCode') }}:
          <span class="text-info text-uppercase">{{ bookingData.bookingCode }}</span>
        </div>

        <div class="text-warning font-italic">
          <div>(*) Lựa chọn hành khách để thêm Passport/CCCD</div>
          <div>(**) Vui lòng không nhập thông tin khống</div>
          <div>(***) Bạn sẽ chịu trách nhiệm bồi thường cho hãng nếu thông tin sai</div>
        </div>
      </div>

      <div>
        <div
          v-for="(paxDocumentInfo, paxDocumentInfoIndex) in paxData"
          :key="paxDocumentInfo.paxId"
        >
          <b-form-checkbox
            :id="`paxDocumentInfo-${paxDocumentInfoIndex}`"
            v-model="paxDocumentInfo.isCheck"
            :name="`paxDocumentInfo-${paxDocumentInfoIndex}`"
          >
            <span class="fw-600 text-uppercase">
              {{ paxDocumentInfo.paxId }}. {{ paxDocumentInfo.title }} {{ paxDocumentInfo.lastName }} {{ paxDocumentInfo.firstName }}
            </span>
          </b-form-checkbox>

          <div v-if="paxDocumentInfo.isCheck">
            <b-input-group>
              <template #prepend>
                <b-form-select
                  v-model="paxDocumentInfo.documentType"
                  :options="documentTypeOptions"
                />
              </template>
              <b-form-input
                v-if="['C', 'P'].includes(paxDocumentInfo.documentType)"
                v-model="paxDocumentInfo.number"
                lazy-formatter
                :formatter="trimAllInputAndUpper"
                :placeholder="paxDocumentInfo.documentType === 'P' ? $t('flight.Passport') : $t('reservation.addDocuments.CCCD')"
              />

              <flat-pickr
                v-else
                v-model="paxDocumentInfo.birthday"
                :name="$t('flight.dob')"
                class="form-control"
                :config="{
                  ...configFlatPickr,
                  altInputClass: 'form-control',
                }"
                :placeholder="$t('flight.dob')"
              />
            </b-input-group>

            <div
              v-if="paxDocumentInfo.documentType === 'P'"
              class="d-flex flex-column gap-2 my-50"
            >
              <!-- ANCHOR Nationality -->
              <div>
                <validation-provider
                  #default="validationContext"
                  rules="required"
                  :name="$t('flight.Nationality')"
                  :vid="`passport-nationality-${paxDocumentInfoIndex}`"
                >
                  <b-form-group
                    class="mb-0 p-0"
                    :class="validationContext.errors[0] ? 'is-invalid' : ''"
                  >
                    <v-select
                      :id="`passport-nationality-${paxDocumentInfoIndex}`"
                      v-model="paxDocumentInfo.nationality"
                      :options="nationalOptions"
                      label="longCode"
                      :reduce="val => val.longCode"
                      :placeholder="$t('flight.Nationality')"
                      searchable
                      :filterable="false"
                      @open="openCountrySelect"
                      @search="searchCountrySelect"
                    >
                      <template #selected-option="item">
                        <div class="cursor-pointer">
                          <b>{{ item.countryName }}</b> (<span class="text-warning font-weight-bolder">{{ item.longCode }}</span>)
                        </div>
                      </template>
                      <template #option="item">
                        <div class="cursor-pointer">
                          <b>{{ item.countryName }}</b> (<span class="text-warning font-weight-bolder">{{ item.longCode }}</span>)
                        </div>
                      </template>
                      <template #spinner="{ loading }">
                        <div
                          v-if="loading"
                          style="border-left-color: rgba(88, 151, 251, 0.71)"
                          class="vs__spinner"
                        />
                      </template>
                      <template #no-options>
                        {{ $t('noOptions') }}
                      </template>
                    </v-select>
                    <b-tooltip
                      v-if="validationContext.errors[0]"
                      :target="`passport-nationality-${paxDocumentInfoIndex}`"
                      triggers="hover focus"
                      placement="top"
                      boundary="window"
                      variant="danger"
                      :title="validationContext.errors[0]"
                    >
                      <span class="text-white py-25 mb-0">
                        {{ validationContext.errors[0] }}
                      </span>
                    </b-tooltip>
                  </b-form-group>
                </validation-provider>
              </div>

              <!-- ANCHOR passport birthday -->
              <div>
                <validation-provider
                  #default="validationContext"
                  rules="required"
                  :name="$t('flight.dob')"
                  :vid="`passport-birthday-${paxDocumentInfoIndex}`"
                >
                  <b-form-group
                    :id="`passport-birthday-${paxDocumentInfoIndex}`"
                    class="mb-0"
                  >
                    <flat-pickr
                      v-model="paxDocumentInfo.birthday"
                      class="d-none"
                    />
                    <div
                      class="form-control p-0"
                      :class="validationContext.errors[0] ? 'is-invalid' : ''"
                    >
                      <flat-pickr
                        :id="`passport-birthday-${paxDocumentInfoIndex}`"
                        v-model="paxDocumentInfo.birthday"
                        :name="$t('flight.dob')"
                        class="form-control px-50 py-0"
                        :config="{
                          ...configFlatPickr,
                          altInputClass: 'form-control input px-1 py-0 border-transparent',
                        }"
                        :placeholder="$t('flight.dob')"
                      />
                    </div>
                    <b-tooltip
                      v-if="validationContext.errors[0]"
                      :target="`passport-birthday-${paxDocumentInfoIndex}`"
                      triggers="hover focus"
                      placement="top"
                      boundary="window"
                      variant="danger"
                      :title="validationContext.errors[0]"
                    >
                      <span class="text-white py-25 mb-0">
                        {{ validationContext.errors[0] }}
                      </span>
                    </b-tooltip>
                  </b-form-group>
                </validation-provider>
              </div>

              <!-- ANCHOR passport Place Issued -->
              <div>
                <validation-provider
                  #default="validationContext"
                  rules="required"
                  :name="$t('flight.placeIssue')"
                  :vid="`passport-placeIssue-${paxDocumentInfoIndex}`"
                >
                  <b-form-group
                    class="mb-0 p-0"
                    :class="validationContext.errors[0] ? 'is-invalid' : ''"
                  >
                    <v-select
                      :id="`passport-placeIssue-${paxDocumentInfoIndex}`"
                      v-model="paxDocumentInfo.isCountry"
                      :options="nationalOptions"
                      label="longCode"
                      :reduce="val => val.longCode"
                      :placeholder="$t('flight.placeIssue')"
                      searchable
                      :filterable="false"
                      @open="openCountrySelect"
                      @search="searchCountrySelect"
                    >
                      <template #selected-option="item">
                        <div class="cursor-pointer">
                          <b>{{ item.countryName }}</b> (<span class="text-warning font-weight-bolder">{{ item.longCode }}</span>)
                        </div>
                      </template>
                      <template #option="item">
                        <div class="cursor-pointer">
                          <b>{{ item.countryName }}</b> (<span class="text-warning font-weight-bolder">{{ item.longCode }}</span>)
                        </div>
                      </template>
                      <template #spinner="{ loading }">
                        <div
                          v-if="loading"
                          style="border-left-color: rgba(88, 151, 251, 0.71)"
                          class="vs__spinner"
                        />
                      </template>
                      <template #no-options>
                        {{ $t('noOptions') }}
                      </template>
                    </v-select>
                    <b-tooltip
                      v-if="validationContext.errors[0]"
                      :target="`passport-placeIssue-${paxDocumentInfoIndex}`"
                      triggers="hover focus"
                      placement="top"
                      boundary="window"
                      variant="danger"
                      :title="validationContext.errors[0]"
                    >
                      <span class="text-white py-25 mb-0">
                        {{ validationContext.errors[0] }}
                      </span>
                    </b-tooltip>
                  </b-form-group>
                </validation-provider>
              </div>

              <!-- ANCHOR passport expirationDate -->
              <div>
                <validation-provider
                  #default="validationContext"
                  rules="required"
                  :name="$t('flight.expirationDate')"
                  :vid="`passport-expirationDate-${paxDocumentInfoIndex}`"
                >
                  <b-form-group
                    :id="`passport-expirationDate-${paxDocumentInfoIndex}`"
                    class="mb-0"
                  >
                    <flat-pickr
                      v-model="paxDocumentInfo.endDate"
                      class="d-none"
                    />
                    <div
                      class="form-control p-0"
                      :class="validationContext.errors[0] ? 'is-invalid' : ''"
                    >
                      <flat-pickr
                        :id="`passport-expirationDate-${paxDocumentInfoIndex}`"
                        v-model="paxDocumentInfo.endDate"
                        :name="$t('flight.expirationDate')"
                        class="form-control px-50 py-0"
                        :config="{
                          ...configFlatPickrPassport,
                          altInputClass: 'form-control input px-1 py-0 border-transparent',
                        }"
                        :placeholder="`${$t('flight.expirationDate')}`"
                      />
                    </div>
                    <b-tooltip
                      v-if="validationContext.errors[0]"
                      :target="`passport-expirationDate-${paxDocumentInfoIndex}`"
                      triggers="hover focus"
                      placement="top"
                      boundary="window"
                      variant="danger"
                      :title="validationContext.errors[0]"
                    >
                      <span class="text-white py-25 mb-0">
                        {{ validationContext.errors[0] }}
                      </span>
                    </b-tooltip>
                  </b-form-group>
                </validation-provider>
              </div>

              <!-- ANCHOR gender -->
              <div>
                <validation-provider
                  #default="validationContext"
                  rules="required"
                  :name="$t('flight.gender')"
                  :vid="`passport-gender-${paxDocumentInfoIndex}`"
                >
                  <b-form-group
                    :id="`passport-gender-${paxDocumentInfoIndex}`"
                    class="mb-0"
                  >
                    <v-select
                      v-model="paxDocumentInfo.gender"
                      :reduce="val => val.value"
                      label="label"
                      class="w-100 p-0"
                      :options="genderOptions.filter(item => item.value !== 'OTHER')"
                      :placeholder="$t('flight.gender')"
                    >
                      <template #option="data">
                        <span>
                          {{ $t(data.label) }}
                        </span>
                      </template>

                      <template #selected-option="data">
                        <span>
                          {{ $t(data.label) }}
                        </span>
                      </template>

                      <template #no-options>
                        {{ $t('noOptions') }}
                      </template>
                    </v-select>
                    <b-tooltip
                      v-if="validationContext.errors[0]"
                      :target="`passport-gender-${paxDocumentInfoIndex}`"
                      triggers="hover focus"
                      placement="top"
                      boundary="window"
                      variant="danger"
                      :title="validationContext.errors[0]"
                    >
                      <span class="text-white py-25 mb-0">
                        {{ validationContext.errors[0] }}
                      </span>
                    </b-tooltip>
                  </b-form-group>
                </validation-provider>
              </div>
            </div>

            <!-- ANCHOR: Thêm cảnh báo "hộ chiếu sắp hết hạn" nếu thời gian hết hạn hộ chiếu < thời gian bay 6 tháng (https://discord.com/channels/1054696448110903327/1300290501815308328/1300290507137875971) -->
            <div
              v-if="flightMaxDate && isLastFlightDateAfterPassportExpiry(paxDocumentInfo.endDate, flightMaxDate)"
              class="text-danger"
            >
              <small>{{ $t('reservation.isLastFlightDateAfterPassportExpiry') }}</small>
            </div>
            <div
              v-else-if="flightMaxDate && checkPassportLessThan6MonthsBeforeFlightDate(paxDocumentInfo.endDate, flightMaxDate)"
              class="text-danger"
            >
              <small>{{ $t('reservation.passportLessThan6MonthsBeforeFlight') }}</small>
            </div>
          </div>
        </div>
      </div>

      <template #modal-footer="{ cancel }">
        <div class="w-100 text-center">
          <b-button
            size="md"
            variant="outline-secondary"
            class="center rounded-pill px-1 px-md-2"
            @click="cancel()"
          >
            {{ $t('reservation.back') }}
          </b-button>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="btn-gradient border-right-0 px-1 px-md-2 ml-75 ml-md-2"
            pill
            :disabled="invalid || isDisabledSubmit"
            @click="submitHandle"
          >
            <div class="d-flex-center px-25">
              {{ $t('reservation.confirmChange') }}
            </div>
          </b-button>
        </div>
      </template>
    </b-modal>
    <ModalShowText
      :text="responseCommand"
      :title-modal="$t('reservation.addDocuments.title')"
      :id-modal="idModal"
    />
  </validation-observer>
</template>

<script>
import {
  BButton,
  BModal,
  BFormInput,
  BInputGroup,
  BFormSelect,
  BFormCheckbox,
  BTooltip,
  BFormGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { toRefs, ref, computed } from '@vue/composition-api'
import flatPickr from 'vue-flatpickr-component'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import debounce from 'lodash/debounce'

import YearDropdownPlugin from '@/libs/flatpickr/yearDropdownPlugin'
import VueI18n from '@/libs/i18n'
import { genderOptions } from '@/constants/selectOptions'
import { apiReservation } from '@/api/'
import {
  checkPassportLessThan6MonthsBeforeFlightDate, getMaxTime, isLastFlightDateAfterPassportExpiry,
} from '@/@core/utils/filter'

import { removeAccents, trimAllInputAndUpper } from '@core/comp-functions/forms/formatter-input'

import useReservationHandle from '@reservation/useReservationHandle'

export default {
  components: {
    BModal,
    BButton,
    BFormInput,
    BInputGroup,
    BFormSelect,
    BFormCheckbox,
    BTooltip,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    vSelect: () => import('vue-select'),
    flatPickr,
    ModalShowText: () => import('@reservation/find-ticket/ModalShowText.vue'),
  },
  props: {
    bookingData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { root }) {
    const { addDocumentsVN1A } = useReservationHandle()
    const { bookingData } = toRefs(props)

    const documentTypeOptions = ref([
      { text: root.$t('reservation.addDocuments.CCCD'), value: 'C' },
      { text: root.$t('reservation.addDocuments.passport'), value: 'P' },
      { text: root.$t('reservation.addDocuments.birthday'), value: 'A' },
    ])

    const paxData = ref(bookingData.value.paxLists.map(pax => ({
      isCheck: false,
      documentType: 'P',
      firstName: pax.firstName,
      lastName: pax.lastName,
      title: pax.title,
      paxId: pax.paxId,
      number: '',
      birthday: pax.birthday,
      nationality: '',
      // residence: '',
      isCountry: '',
      // startDate: getToday('YYYY-MM-DD'),
      endDate: '',
      gender: '',
    })))

    // ANCHOR flatpickr config
    const locale = computed(() => VueI18n.locale).value

    const configFlatPickr = ref(
      {
        plugins: [
          new YearDropdownPlugin({
            date: new Date(),
            yearStart: 100,
            yearEnd: 0,
          }),
        ],
        dateFormat: 'Y-m-d',
        minDate: '1.1.1920',
        maxDate: 'today',
        locale: locale === 'vi' ? Vietnamese : null,
        allowInput: true,
        altInput: true,
        altFormat: 'd-m-Y',
        shorthandCurrentMonth: true,
        disableMobile: true,
      },
    )

    const configFlatPickrPassport = ref(
      {
        plugins: [
          new YearDropdownPlugin({
            date: new Date(),
            yearStart: 0,
            yearEnd: 50,
          }),
        ],
        dateFormat: 'Y-m-d',
        minDate: 'today',
        maxDate: '',
        locale: locale === 'vi' ? Vietnamese : null,
        allowInput: true,
        altInput: true,
        altFormat: 'd-m-Y',
        shorthandCurrentMonth: true,
        disableMobile: true,
      },
    )

    const responseCommand = ref('')
    const idModal = ref('add-documents')
    async function submitHandle() {
      this.$bvModal.show('modal-api-loading')
      const paxDocumentInfos = paxData.value.filter(i => i?.isCheck && i?.documentType).map(p => {
        let passport
        let identificationNumber
        if (p.documentType === 'P') {
          passport = {
            paxId: p.paxId,
            docType: p.documentType,
            number: p.number,
            nationality: p.nationality,
            residence: p.residence || undefined,
            isCountry: p.isCountry,
            startDate: p?.startDate || undefined,
            endDate: p.endDate,
            birthday: p.birthday,
            gender: p.gender,
          }
        } else if (p.documentType === 'C') {
          identificationNumber = p.number
        }

        return {
          documentType: p.documentType,
          paxInfo: {
            firstName: p.firstName,
            lastName: p.lastName,
            title: p.title,
          },
          passport,
          birthday: p.documentType === 'A' ? p.birthday : undefined,
          identificationNumber,
        }
      })
      const payload = {
        source: bookingData.value.source,
        pnrNumber: bookingData.value.bookingCode,
        agencyCode: bookingData.value.agency,
        paxDocumentInfos,
      }
      try {
        const res = await addDocumentsVN1A(payload)
        responseCommand.value = res?.booking.length ? res?.booking.map(i => i?.data || '').join('\n') : ''
        this.$bvModal.show(`modal-reservation-show-text${idModal.value}`)
      } finally {
        this.$bvModal.hide('modal-api-loading')
        this.$bvModal.hide('modal-add-documents-vn1a')
      }
    }

    // ANCHOR countryOptions
    const nationalOptions = ref([])
    const nationalOptionsDefault = ref([])

    function fetchCountryList(searchText = '') {
      apiReservation.getCountriesName({ searchText: removeAccents(searchText) })
        .then(res => {
          nationalOptions.value = res.data.items
          nationalOptionsDefault.value = res.data.items
        })
    }

    function openCountrySelect() {
      if (!nationalOptionsDefault.value.length) {
        fetchCountryList()
      } else {
        nationalOptions.value = nationalOptionsDefault.value
      }
    }

    const searchCountrySelect = debounce(searchText => {
      if (searchText) fetchCountryList(searchText)
    }, 200)

    function showHandle() {
      if (!nationalOptionsDefault.value.length) fetchCountryList('VNM')
    }

    const isDisabledSubmit = computed(() => paxData.value.filter(p => p.documentType && p.isCheck && (p.documentType === 'A' ? p.birthday : p.number)).length === 0)

    const flightMaxDate = computed(() => {
      const allDepartureDate = bookingData.value.itineraries?.flat()?.map(item => item.departure.at)
      return getMaxTime(allDepartureDate)
    })
    return {
      showHandle,
      submitHandle,
      paxData,
      documentTypeOptions,
      Vietnamese,
      configFlatPickr,
      configFlatPickrPassport,
      genderOptions,
      searchCountrySelect,
      openCountrySelect,
      flightMaxDate,
      nationalOptions,
      isDisabledSubmit,
      responseCommand,
      idModal,
      trimAllInputAndUpper,
      checkPassportLessThan6MonthsBeforeFlightDate,
      isLastFlightDateAfterPassportExpiry,
      getMaxTime,

    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>

</style>
